.data-picker-column {
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
}

.data-picker-delimiter {
    height: 38px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 20px;
        height: 10px;
    }
}

@include media-breakpoint-down(sm) {
    .data-picker-delimiter {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
