%card-stats {
    hr {
        margin: 5px 15px;
    }
}

.card-stats {
    @extend %card-stats;

    .info-icon {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        @include diagonal-gradient($primary, $primary-states);

        i {
            color: $white;
            font-size: 1.7em;
            padding: 14px 13px;
        }

        &.icon-danger {
            @include diagonal-gradient($danger, $danger-states);
        }

        &.icon-warning {
            @include diagonal-gradient($warning, $warning-states);
        }

        &.icon-success {
            @include diagonal-gradient($success, $success-states);
        }

        &.icon-info {
            @include diagonal-gradient($info, $info-states);
        }

        &.icon-primary {
            @include diagonal-gradient($primary, $primary-states);
        }
    }

    .numbers {
        text-align: right;
        .card-title {
            margin-top: 0;
        }
        .card-category {
            margin-bottom: 0;
            color: $dark-gray;
        }
    }

    .card-footer {
        padding: 0;
    }

    hr {
        border-color: lighten($black, 5%);
    }

    .stats {
        color: rgba($white, 0.6);
        margin: 10px 15px;
        font-size: 0.9em;
    }

    &.card-white {
        hr {
            border-color: rgba(43, 53, 83, 0.2);
        }
    }
}

@include media-breakpoint-down(sm) {
    .card-stats [class*="col-"] .statistics::after {
        display: none;
    }
}
