@include media-breakpoint-up(xl) {
    .container-lg {
        max-width: 1160px;
    }
}

.catalog-grid {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
        justify-content: flex-start;
    }
}
