.version {
    position: absolute;
    top: 2px;
    right: 30px;
    z-index: 1;
}

.content-line:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: hsla(0, 0%, 100%, 0.1);
}

.property-block {
    width: 100%;
    height: 55px;
    padding: 8px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;

    &-primary {
        background-color: #1e1e2f;
    }

    &-secondary {
        background-color: #2b3553;
    }

    span {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    .title {
        width: 100%;
    }

    .subtitle {
        width: 70%;
    }
}

.copy-button,
.external-link-button,
.expand-button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;

    @include customButtonHoverMixin;

    &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }
    .copy-button-icon,
    .external-link-icon {
        height: 20px;
        width: 20px;
        max-width: 20px;
    }
}

.pagination-custom {
    .pagination {
        margin-bottom: 0;
    }
}

@media (max-width: 765px) {
    .go-to {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .cmb-sm-2 {
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 460px) {
    .go-to {
        flex: 1 1 auto;
    }

    .cmb-sm-2 {
        flex-basis: auto;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }
}

@media (max-width: 991px) {
    .cmb-mg-2 {
        margin-bottom: 0.5rem;
    }
}

.loader {
    .small {
        width: 30px;
        height: 30px;
    }
    .default {
        width: 50px;
        height: 50px;
    }
    .large {
        width: 90px;
        height: 90px;
    }
}

.modal-loader {
    width: 180px;
    height: 180px;
}

.description-property {
    padding: 18px 16px;
    border-radius: 8px;
    background-color: #2b3553;

    .title {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
    }

    .subtitle {
        color: rgb(255, 255, 255);
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
    }

    a.subtitle {
        text-decoration: underline;

        @include customButtonHoverMixin;
    }
}

.custom-tooltip {
    display: inline-block;
    vertical-align: top;
}

.user-description {
    .avatar-wrapper {
        display: flex;

        .avatar-icon-wrapper {
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    @include media-breakpoint-down(sm) {
        .avatar-wrapper {
            flex: 0 0 100%;
            max-width: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    .user-properties {
        .col-property {
            @extend .col-auto;
            @extend .mb-2;
        }

        @include media-breakpoint-down(sm) {
            .col-property {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    .description-content-property {
        width: 300px;
        height: 140px;
    }

    @include media-breakpoint-down(sm) {
        .description-content-property {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.video-description {
    .video-player-col {
        @extend .col-auto;

        .video-player-wrapper {
            height: 270px;
            width: 330px;
            @extend .position-relative;
        }

        @include media-breakpoint-down(sm) {
            .video-player-wrapper {
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .video-player-col {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .video-properties {
        .col-property {
            @extend .col-auto;
            @extend .mb-2;
        }

        @include media-breakpoint-down(sm) {
            .col-property {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    .description-content-property {
        width: 300px;
        height: 140px;
    }

    @include media-breakpoint-down(sm) {
        .description-content-property {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.description-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h4 {
        line-height: 18px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
        color: rgba(255, 255, 255, 0.5);
        padding: 5px 0;
    }

    .content-wrapper {
        background-color: black;
        border-radius: 8px;
        height: 100px;
        padding: 13px;
        overflow: auto;
    }
}

.user-card {
    border-radius: 8px;
    .not-verified-badge {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.user-report {
    &-body {
        h4,
        h5 {
            color: black;
            margin-bottom: 3px;
        }
    }
    &-loader {
        height: 470px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.description-icon {
    cursor: pointer;
}

.organization-card {
    .user-link {
        color: white;
    }

    .user-link:hover {
        text-decoration: underline;
    }
}
