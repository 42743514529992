.custom-switch {
    .custom-control-label {
        margin-bottom: 0px;
        line-height: 22px;
        vertical-align: middle;
        &::before {
            height: 22px;
            width: 45px;
            border: none;
            background-image: url("~assets/img/darken-night-sky-full-of-stars-fantasy-animation-made-of-magical-sparkly-light-particles-flickering-on-a-purple-blue-gradient-background_bajacpz7x_thumbnail-full01.png");
            background-position: center center;
            background-size: cover;
        }
        &::after {
            height: 18px;
            width: 18px;
            background-color: rgba(156, 156, 156, 0.9);
            transition: all 0.25s ease-out;
        }
    }
    .custom-control-input:checked {
        ~ .custom-control-label::before {
            background-image: url("~assets/img/night-sky-full-of-stars-fantasy-animation-made-of-magical-sparkly-light-particles-flickering-on-a-purple-blue-gradient-background_bajacpz7x_thumbnail-full01.png");
        }
        ~ .custom-control-label::after {
            background-color: $white;
            transform: translateX(23px);
        }
    }
    &:hover {
        .custom-control-label::after {
            width: 23px;
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            margin-left: -5px;
        }
    }
}
