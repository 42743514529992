.rna-container {
    z-index: 1051;
    position: fixed;
}
.alert {
    button.close {
        margin-top: -1px;
        color: $white;
        opacity: 0.9;
        text-shadow: none;
        line-height: 0;
        outline: 0;
        &::after {
            display: inline-block;
            font: normal normal normal 1em/1 "Nucleo";
            vertical-align: middle;
            speak: none;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            font-size: 1rem;
            content: "\ea48";
        }
        span {
            display: none !important;
        }
    }
    &.alert-dismissible {
        padding-right: 1.25rem;
    }
}
