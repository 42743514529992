// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.
.progress-container {
    position: relative;

    &.progress-sm {
        margin-top: 10px;

        .progress {
            .progress-value {
                position: absolute;
                top: -3px;
                left: -27px;
                color: $white;
                font-size: $font-size-xs;
            }
        }
    }

    & + .progress-container,
    & ~ .progress-container {
        margin-top: $margin-base-vertical;
    }
    .progress-badge {
        color: $white;
        font-size: $font-size-sm;
        text-transform: uppercase;

        &.float-left {
            margin-right: 20px;
        }
    }

    .progress {
        margin-bottom: 10px;
        box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.3);

        .progress-bar {
            border-radius: $border-radius-xl;
            box-shadow: none;
            background: $default;

            .progress-value {
                position: absolute;
                top: 2px;
                right: 0;
                color: $white;
                font-size: $font-size-xs;
            }
        }

        &.progress-bar-sm {
            height: 3px;
        }
    }

    &.progress-neutral {
        .progress {
            background: rgba(255, 255, 255, 0.3);
        }

        .progress-bar {
            background: $white;
        }
    }

    &.progress-primary {
        .progress-bar {
            @include diagonal-gradient($primary-states, $primary);
        }
    }

    &.progress-info {
        .progress-bar {
            @include diagonal-gradient($info-states, $info);
        }
    }

    &.progress-success {
        .progress-bar {
            @include diagonal-gradient($success-states, $success);
        }
    }

    &.progress-warning {
        .progress-bar {
            @include diagonal-gradient($warning-states, $warning);
        }
    }

    &.progress-danger {
        .progress-bar {
            @include diagonal-gradient($danger-states, $danger);
        }
    }
}

.card-chart {
    .progress-container + .progress-container,
    .progress-container ~ .progress-container {
        margin-top: 25px;
    }
}
