/**
  * bootstrap-switch - Turn checkboxes and radio buttons into toggle switches.
  *
  * @version v3.3.4
  * @homepage https://bttstrp.github.io/bootstrap-switch
  * @author Mattia Larentis <mattia@larentis.eu> (http://larentis.eu)
  * @license MIT
  */

//* IMPORTANT! Creative Tim Notice: this file has major changes to fit the Black Dashboard 's design. If you want to use the plugin as it was before our changes, please get the old files from http://www.bootstrap-switch.org

.bootstrap-switch {
    display: inline-block;
    direction: ltr;
    cursor: pointer;
    border-radius: 30px;
    border: 0;
    position: relative;
    text-align: left;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.13);
    margin-bottom: 10px;
    line-height: 8px;
    width: 59px !important;
    height: 22px;
    outline: none;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    background: url("https://d2v9y0dukr6mq2.cloudfront.net/video/thumbnail/J9H9WF0/night-sky-full-of-stars-fantasy-animation-made-of-magical-sparkly-light-particles-flickering-on-a-purple-blue-gradient-background_bajacpz7x_thumbnail-full01.png");
    background-position: center center;
    background-size: cover;
}

.bootstrap-switch .bootstrap-switch-container {
    display: inline-flex;
    top: 0;
    height: 22px;
    border-radius: 4px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    width: 100px !important;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block !important;
    height: 100%;
    color: #fff;
    padding: 6px 10px;
    font-size: 11px;
    text-indent: -5px;
    line-height: 15px;
    -webkit-transition: 0.25s ease-out;
    transition: 0.25s ease-out;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
    text-align: center;
    z-index: 1;
    float: left;
    line-height: 11px;
    width: 50% !important;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-brown,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-brown {
    color: #fff;
    background: $brand-primary;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-blue,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-blue {
    color: #fff;
    background: $brand-info;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-green,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-green {
    color: #fff;
    background: $brand-success;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-orange,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-orange {
    background: $brand-warning;
    color: #fff;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-red,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-red {
    color: #fff;
    background: $brand-danger;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
    color: #fff;
}
.bootstrap-switch .bootstrap-switch-label {
    text-align: center;
    z-index: 100;
    color: #333333;
    background: #ffffff;
    width: 18px !important;
    height: 18px !important;
    margin: 0px -11px;
    border-radius: 20px;
    position: absolute;
    float: left;
    top: 2px;
    left: 51%;
    padding: 0;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.25);
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
    background-color: rgba(156, 156, 156, 0.9);
    left: 52%;
}
.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
    width: 27px !important;
    margin-left: -20px;
}
.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
    width: 27px !important;
    margin-left: -11px;
}
.bootstrap-switch .bootstrap-switch-handle-on {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}
.bootstrap-switch .bootstrap-switch-handle-off {
    text-indent: 6px;
}
.bootstrap-switch input[type="radio"],
.bootstrap-switch input[type="checkbox"] {
    position: absolute !important;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: -1;
}
.bootstrap-switch input[type="radio"].form-control,
.bootstrap-switch input[type="checkbox"].form-control {
    height: auto;
}
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
    padding: 6px 16px;
    font-size: 18px;
    line-height: 1.33;
}
.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
    cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
    opacity: 0.5;
    filter: alpha(opacity=50);
    cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
    -webkit-transition: margin-left 0.5s;
    transition: margin-left 0.5s;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}
.bootstrap-switch.bootstrap-switch-focused {
    // border-color: #66afe9;
    // outline: 0;
    // -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
    // box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
// .bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
// .bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
//   border-bottom-right-radius: 3px;
//   border-top-right-radius: 3px;
// }
// .bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
// .bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
//   border-bottom-left-radius: 3px;
//   border-top-left-radius: 3px;
// }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
    margin-left: -2px !important;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
    margin-left: -39px !important;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label {
    &:before {
        background-color: #ffffff;
    }
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-red ~ .bootstrap-switch-default {
    background-color: $brand-danger;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-orange ~ .bootstrap-switch-default {
    background-color: $brand-warning;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-green ~ .bootstrap-switch-default {
    background-color: $brand-success;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-brown ~ .bootstrap-switch-default {
    background-color: $brand-primary;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-blue ~ .bootstrap-switch-default {
    background-color: $brand-info;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-red,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-brown,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-blue,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-orange,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-green {
    background-color: $light-gray;
}

.bootstrap-switch-on .bootstrap-switch-handle-off,
.bootstrap-switch-off .bootstrap-switch-handle-on {
    opacity: 0;
    visibility: hidden;
}

.bootstrap-switch-off:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    background: rgba(16, 16, 16, 0.7);
}
