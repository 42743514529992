.noUi-vertical {
    width: 1px;
    height: 100%;
    .noUi-handle {
        outline: none;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        cursor: pointer;
        left: -7px;
        top: -10px;
    }
}
