@each $color, $value in $colors {
    @include bg-variant(".bg-#{$color}", $value, $ignore-warning: true);
}

@each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value, $ignore-warning: true);
}

@each $color, $value in $colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value, $ignore-warning: true);
}

// Sections

section {
    //background-color: section-color("primary");
}

@each $color, $value in $section-colors {
    @include bg-variant(".section-#{$color}", $value, $ignore-warning: true);
}

@each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value, $ignore-warning: true);
}

// Shape (svg) fill colors
@each $color, $value in $theme-colors {
    .fill-#{$color} {
        fill: $value;
    }

    .stroke-#{$color} {
        stroke: $value;
    }
}

.fill-opacity-8 {
    fill-opacity: 0.8;
}
