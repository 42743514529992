body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

iframe {
    border: 0;
}

figcaption,
figure,
main {
    display: block;
}

main {
    overflow: hidden;
}
