/*!
* https://github.com/YouCanBookMe/react-datetime
*/
.rdt {
    position: relative;
    .rdtPicker {
        -webkit-transition: all 150ms linear;
        -moz-transition: all 150ms linear;
        -o-transition: all 150ms linear;
        -ms-transition: all 150ms linear;
        transition: all 150ms linear;
        margin-top: -20px;
        visibility: hidden;
        display: block;
        opacity: 0;
    }
    &.rdtOpen {
        .rdtPicker {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
        }
    }
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 260px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    border-radius: 0.125rem;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    min-width: 160px;

    &:before {
        display: inline-block;
        position: absolute;
        width: 0;
        height: 0;
        vertical-align: middle;
        content: "";
        top: -5px;
        left: 10px;
        right: auto;
        color: #ffffff;
        border-bottom: 0.4em solid;
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
    }
    &:after {
        border-bottom: 0.4em solid #ffffff;
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
        content: "";
        display: inline-block;
        position: absolute;
        top: -5px;
        left: 10px;
    }
}

.rdtPicker {
    display: block;
    top: 40px;
}
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPicker .rdtTimeToggle {
    text-align: center;
    padding: 10px;
    border-radius: 4px;
    visibility: hidden;
    position: relative;
    &:before {
        visibility: visible;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal normal 1em/1 "Nucleo";
        font-size: 18px;
        content: "\EA5E";
    }
}

.rdtPicker table {
    width: 100%;
    margin: 0;
    border-color: $white !important;
}
.rdtPicker td,
.rdtPicker th {
    text-align: center;
    padding: 1px;
}
.rdtPicker td {
    cursor: pointer;
}
.rdtDay {
    height: 30px;
    line-height: 33px;
    width: 30px;
    text-align: center;
    padding: 0px;
    border-radius: 50%;
    &.rdtToday.rdtActive,
    &.rdtActive,
    &.rdtActive:hover {
        background-color: $primary !important;
        color: $white !important;
    }
}
.rdtDays {
    tr {
        .dow {
            text-align: center;
            font-size: 12px;
            font-weight: inherit;
            padding-bottom: 5px;
            padding-top: 10px;
            font-weight: 300;
            font-size: 14px;
            width: 20px;
            height: 20px;
        }
        .rdtDay {
            color: $dark-gray;
            &.rdtOld,
            &.rdtNew {
                color: $default;
                font-weight: 300;
            }
        }
    }
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover,
.rdtPicker .rdtTimeToggle:hover:before {
    background: #eeeeee;
    cursor: pointer;
}
.rdtPicker td.rdtToday {
    position: relative;
}
.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
    color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
}
.rdtPicker th.rdtSwitch {
    width: 50px;
    padding: 5px;
    border-radius: 3px;
    height: 20px;
    line-height: 20px;
    font-weight: 300;
    padding: 5px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    border-radius: 50%;
    height: 27px;
    width: 27px;
    display: block;
    &:before {
        top: 2px;
        font-size: 12px;
        border-radius: 50%;
        text-align: center;
        position: relative;
        display: inline-block;
        font: normal normal normal 1em/1 "Nucleo";
        vertical-align: middle;
        speak: none;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
    }
    span {
        display: none;
    }
}

.rdtPicker {
    .dow,
    th.rdtSwitch,
    th.rdtNext,
    th.rdtPrev,
    .rdtTimeToggle {
        color: $primary;
    }
}

.rdtPicker th.rdtNext {
    &:before {
        content: "\ea35";
    }
}

.rdtPicker th.rdtPrev {
    &:before {
        content: "\ea34";
    }
}

.rdtPrev span,
.rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
    cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
}

.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}
.rdtPicker button:hover {
    background-color: #eee;
}

.rdtPicker thead button {
    width: 100%;
    height: 100%;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
    background: #eee;
}

.rdtCounters {
    display: inline-block;
}

.rdtCounters {
    > div {
        float: left;
        width: 40px;
        font-weight: inherit;
        margin: 3px;
        border-radius: 50%;
    }
    .rdtCounterSeparator {
        width: 0;
        border: 1px solid transparent;
    }
}

.rdtCounter {
    height: 100px;
}

.rdtCounter {
    width: 40px;
    .rdtCount {
        padding: 7px;
        height: 40px;
        border: 1px solid transparent;
    }
}
.rdtCounters {
    .rdtCounter:last-child {
        .rdtCount {
            color: $primary;
            border-radius: 50%;
            border: 1px solid $primary;
        }
    }
}

.rdtCounterSeparator {
    padding: 7px;
    line-height: 100px;
}
.rdtCounter .rdtBtn {
    line-height: 40px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    color: $medium-gray;
    -webkit-transition: all 60ms ease-in;
    -moz-transition: all 60ms ease-in;
    -o-transition: all 60ms ease-in;
    -ms-transition: all 60ms ease-in;
    transition: all 60ms ease-in;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    // cursor: pointer;
    // display: block;
    // user-select: none;
    // padding: 0;
    // border-width: 0;
    // color: #fff;
    // background-color: transparent;
    // cursor: pointer;
    // @include btn-styles($primary, $primary-states-color);
    // margin: 2px 0;
    // transition: 0.3s ease-in-out;
    // &:hover{
    //   background-position: bottom left;
    // }
    // font-weight: 600;
    // font-size: 1em;
    // width: 40px;
    // height: 40px;
    // line-height: 40px;
    // border: none;
    // position: relative;
    // overflow: hidden;
    // border-radius: 0.4285rem;
    // user-select: none;
    // display: inline-block;
    // text-align: center;
    // white-space: nowrap;
    // vertical-align: middle;
}
.rdtCounter .rdtBtn:hover {
    background: #eee;
    color: #797979;
}
.rdtCounter .rdtCount {
    font-size: inherit;
    line-height: 25px;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}

.rdtMilli input {
    width: 100%;
    font-size: inherit;
    margin-top: 37px;
}
.rdtMonths,
.rdtYears {
    padding-bottom: 10px;
    .rdtMonth,
    .rdtYear {
        display: inline-block;
        width: 56px;
        height: 56px;
        line-height: 56px;
        margin: 3px 3px;
        cursor: pointer;
        border-radius: 50%;
        text-align: center;

        &.rdtActive {
            background-color: $primary !important;
            color: $white;
        }
    }
}

.rdtDateTo,
.rdtDateFrom {
    background-color: #eeeeee !important;
    color: $black !important;
}
