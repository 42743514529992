@include media-breakpoint-down(md) {
    .navbar .dropdown.show .dropdown-menu {
        position: static !important;
    }
    .navbar:not(.bg-white) {
        .dropdown.show .dropdown-menu .dropdown-item {
            color: $opacity-8;
        }
        .navbar-nav .btn span {
            color: $opacity-8 !important;
        }
    }
    nav.navbar {
        top: 0px;
    }
}
@include media-breakpoint-down(sm) {
    .navbar .navbar-wrapper {
        .navbar-toggle {
            transition: all 1s;
        }
    }
    .navbar .navbar-wrapper {
        .navbar-toggle.toggled {
            left: -20px;
            position: relative;
            right: unset;
            transition: all 1s;
        }
    }
    .rtl {
        .navbar .navbar-wrapper {
            .navbar-toggle.toggled {
                right: -35px;
                position: relative;
                left: unset;
                transition: all 1s;
            }
        }
    }
}
.nav-link:hover {
    color: $info;
}
