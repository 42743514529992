.rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0;
}
button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled].rbc-btn {
    cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
.rbc-calendar {
    box-sizing: border-box;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
    box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.rbc-rtl {
    direction: rtl;
}
.rbc-off-range {
    color: #333333;
    opacity: 0.3;
}
// .rbc-off-range-bg {
//   background: #e5e5e5;
// }
.rbc-header,
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited,
.rbc-header > a:hover,
.rbc-time-gutter.rbc-time-column {
    overflow: hidden;
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em;
    color: rgba(255, 255, 255, 0.4);
    box-sizing: content-box;
    text-align: right;
    font-weight: bold;
    line-height: 1.5;
    text-decoration: none;
    &:last-child {
        span {
            padding-right: 15px;
        }
    }
    .rbc-time-slot {
        font-weight: 400;
    }
}

.rbc-row-content {
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    z-index: 4;
    min-height: 173px;
}
.rbc-toolbar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1em;
    font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
    margin: 0;
    font-size: 1.8em;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    font-family: inherit;
    line-height: 1.2;
}
.rbc-toolbar button {
    border-width: $border-thick;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    line-height: $line-height;
    margin: 5px 1px;
    border: none;
    margin: 10px 1px;
    border-radius: $border-radius-sm;
    padding: 8px 20px;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    outline: none;
    text-transform: lowercase;
    &::-moz-focus-inner {
        border: 0;
    }
    @include btn-styles($info, $info-states);
}
.rbc-toolbar button:focus {
    outline: none;
}
.rbc-btn-group {
    display: inline-block;
    &:last-child {
        button:not(.rbc-active):not(:hover) {
            opacity: 0.5;
        }
    }
}

.rbc-btn-group button {
    margin: 0 0 0 2px;
}
@media screen and (max-width: 526px) {
    .rbc-btn-group button {
        margin: 2px !important;
    }
}

.rbc-btn-group button + button {
    margin: 0 0 0 2px;
}
// .rbc-rtl .rbc-btn-group button + button {
//   margin-left: 0;
//   margin-right: -1px;
// }
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
    margin-left: 10px;
}

.rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none;
}
.rbc-event.rbc-selected {
    background-color: #265985;
}
.rbc-event-label {
    font-size: 80%;
}
.rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.rbc-event-continues-day-after {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.rbc-event-continues-day-prior {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.rbc-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
.rbc-row-segment {
    padding: 2px 6px 1px;
}
.rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1);
}
.rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
    white-space: nowrap;
}
.rbc-month-view {
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
    border-left: none;
    border-right: none;
}
.rbc-month-view {
    border-top: none;
}
.rbc-month-header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.rbc-month-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    // -webkit-flex-basis: 0px;
    -ms-flex-preferred-size: 0px;
    // flex-basis: 0px;
    overflow: hidden;
    height: 100%;
}
.rbc-month-row {
    border-style: solid;
    border-width: 1px;
    padding: 0;
    vertical-align: top;
    border-color: #2b3553;
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
}
.rbc-date-cell {
    flex: 1 1;
    min-width: 0;
    padding-right: 3px;
    padding-top: 1px;
    text-align: right;
}
.rbc-date-cell.rbc-now {
    font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
    color: white;
    text-decoration: none;
}
.rbc-row-bg {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    overflow: hidden;
}
.rbc-day-bg {
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
}
.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #2b3553;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #2b3553;
}
.rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
}
.rbc-overlay > * + * {
    margin-top: 1px;
}
.rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px;
}
.rbc-agenda-view {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #2b3553;
    border-spacing: 0;
    border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    padding: 5px 10px;
    vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left: 1px solid #2b3553;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-width: 0;
    border-right: 1px solid #2b3553;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top: 1px solid #2b3553;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    padding: 3px 5px;
    text-align: left;
    border-bottom: 1px solid #2b3553;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    text-align: right;
}
.rbc-agenda-time-cell {
    text-transform: lowercase;
    color: rgba(255, 255, 255, 0.6);
}
.rbc-agenda-time-cell .rbc-continues-after:after {
    content: " »";
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
    content: "« ";
}
.rbc-agenda-date-cell {
    color: rgba(255, 255, 255, 0.8);
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
    white-space: nowrap;
}
.rbc-agenda-event-cell {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
}
.rbc-time-column {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
}
.rbc-timeslot-group {
    border-bottom: 1px solid #2b3553;
    min-height: 40px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
}
.rbc-label {
    padding: 0 5px;
}
.rbc-day-slot {
    position: relative;
}
.rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 10px;
    top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-is-rtl {
    left: 10px;
    right: 0;
}
.rbc-day-slot .rbc-event {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
}
.rbc-day-slot .rbc-event-label {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    padding-right: 5px;
    width: auto;
    font-weight: bold;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    color: #fff;
    white-space: nowrap;
    text-align: end;
}
.rbc-day-slot .rbc-event-content {
    width: 100%;
    -webkit-flex: 1 1 0;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em;
}
.rbc-day-slot .rbc-time-slot + .rbc-time-slot {
    border-top: 1px dotted #2b3553;
}
.rbc-time-slot {
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
}
.rbc-time-slot.rbc-now {
    font-weight: bold;
}
.rbc-day-header {
    text-align: center;
}
.rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px;
}
.rbc-slot-selecting {
    cursor: move;
}
.rbc-time-view {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    min-height: 0;
    border-left: none;
    border-right: none;
    border-top: none;
}
.rbc-time-view .rbc-time-gutter {
    white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    position: relative;
}
.rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4;
}
.rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
}
.rbc-time-header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
    border-right: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #ddd;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #ddd;
}
.rbc-time-header-content {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-left: 1px solid #2b3553;
}
.rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #2b3553;
}
.rbc-time-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    border-top: 3px solid $default;
    overflow-y: auto;
    position: relative;
}
.rbc-time-content > .rbc-time-gutter {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
}
.rbc-time-content > * + * > * {
    border-left: 1px solid #2b3553;
}
.rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #2b3553;
}
.rbc-time-content > .rbc-day-slot {
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}
.rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    height: 1px;
    background-color: #74ad31;
    pointer-events: none;
}
.rbc-event {
    width: 100%;
    position: relative; /* for resize handle and other inner positioning */
    display: block; /* make the <a> tag block */
    font-size: 0.85em;
    border-radius: 2px;
    padding: 0;
    background-color: $default; /* default BACKGROUND color */
    // @include shadow-big-color($brand-success);
    font-weight: normal; /* undo jqui's ui-widget-header bold */
    text-align: left;
    border: none;
    padding-bottom: 1px;
    line-height: 1.2;

    &.event-azure {
        background-color: $brand-info;
    }
    &.event-green {
        background-color: darken($brand-success, 10%);
    }
    &.event-orange {
        background-color: $brand-warning;
    }
    &.event-red {
        background-color: $brand-danger;
    }

    .rbc-event-content {
        position: relative;
        z-index: 2;
        white-space: nowrap;
        overflow: hidden;
        color: white;
        padding: 0 5px;
        text-align: right;
    }
}
.rbc-day-slot .rbc-event {
    display: block !important;
    max-height: unset !important;
}

.rbc-today {
    background-color: #252e49;
}
@media screen and (max-width: 1373px) {
    .rbc-toolbar {
        display: block;
        .rbc-btn-group {
            display: block;
            text-align: center;
        }
        .rbc-toolbar-label {
            display: block;
            flex-grow: unset;
            padding: 0;
        }
    }
}
.rbc-row.rbc-time-header-cell {
    border-bottom: 1px solid #2b3553;
}
.rbc-time-view {
    .rbc-row-content {
        min-height: unset;
    }
}
.perfect-scrollbar-on .rbc-calendar .rbc-row-content {
    min-height: 115px;
}
@include media-breakpoint-down(sm) {
    .rbc-calendar .rbc-row-content {
        min-height: 45px;
    }
}
@include media-breakpoint-down(md) {
    .rbc-calendar .rbc-row-content {
        min-height: 90px;
    }
}
