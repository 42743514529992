.rtl {
    .sidebar .nav li.active > a[data-toggle="collapse"]:before,
    .sidebar .nav li.active > a[data-toggle="collapse"] + div .nav .active a:before {
        right: -4px;
        left: auto;
    }
    .sidebar .logo a.logo-mini {
        margin-right: 15px;
        margin-left: 15px;
    }
    .fixed-plugin {
        border-radius: 0 8px 8px 0;
        left: 0;
        right: unset;
        .dropdown-menu {
            left: 80px !important;
            right: auto !important;
            &:before,
            &:after {
                left: 10px;
                margin-right: auto;
                right: auto;
            }
            &:before {
                right: unset;
                left: -16px;
                border-left: unset;
                border-right: 0.4em solid rgba(0, 0, 0, 0.2);
            }
            &:after {
                right: unset;
                left: -16px;
                border-left: unset;
                border-right: 0.4em solid $black;
            }
        }
    }
    .card-tasks .card-header .title {
        margin-right: 0;
        margin-left: 24px;
    }
    .main-panel {
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
    }
}
