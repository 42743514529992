.sidebar .logo .logo-mini .logo-img img {
    //border-radius: 80%;
    width: 30px;
}
.perfect-scrollbar-on {
    .sidebar {
        height: 80%;
    }
}

@include media-breakpoint-down(sm) {
    .sidebar {
        height: 100%;
    }

    html.nav-open .main-panel {
        height: 100vh;
    }
}
@include media-breakpoint-down(md) {
    .perfect-scrollbar-on {
        .sidebar {
            height: 100%;
        }
    }
    html.nav-open body {
        position: relative;
        overflow-x: hidden;
    }
    html.nav-open .main-panel {
        overflow: auto;
        min-height: unset;
    }
    html.nav-open .wrapper {
        overflow: hidden;
    }
}

@include media-breakpoint-down(lg) {
    .perfect-scrollbar-on {
        .sidebar {
            height: 100%;
        }
    }
}

.sidebar .nav li.active > a[data-toggle="collapse"] + div .nav li:not(.active) a {
    .sidebar-normal {
        color: rgba($white, 0.8);
    }
    &::before {
        display: none;
    }
}
