// Based on the original react-tagsinput styles

.react-tagsinput {
    display: inline-block;
    padding: 4px 6px;
    max-width: 100%;
    line-height: 22px;
}

.react-tagsinput-tag {
    cursor: pointer;
    margin: 5px 3px 5px 0;
    position: relative;
    padding: 3px 8px;
    border-radius: 12px;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.75em;
    text-transform: uppercase;
    white-space: nowrap;
    display: inline-block;
    line-height: 1.5em;
    padding-left: 0.8em;
    & + .react-tagsinput-tag {
        margin-left: 3px;
    }
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a.react-tagsinput-remove::before {
    font-family: "Nucleo";
    content: "\ea48";
    padding: 0px 2px;
}
.react-tagsinput-tag a.react-tagsinput-remove {
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 0px;
    opacity: 0;
    background-color: transparent;
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    padding-left: 5px;
    width: 80px;
}

.react-tagsinput {
    .react-tagsinput-tag {
        -webkit-transition: all 300ms ease 0s;
        -moz-transition: all 300ms ease 0s;
        -o-transition: all 300ms ease 0s;
        -ms-transition: all 300ms ease 0s;
        transition: all 300ms ease 0s;

        &:hover {
            &[data-disabled="false"] {
                padding-right: 22px;
            }

            &[data-disabled="true"] {
                cursor: default;
            }

            a.react-tagsinput-remove {
                opacity: 1;
                padding-right: 4px;
            }
        }

        @include badge-variant($default);
        &.primary {
            @include badge-variant($primary);
        }
        &.info {
            @include badge-variant($info);
        }
        &.success {
            @include badge-variant($success);
        }
        &.warning {
            @include badge-variant($warning);
        }
        &.danger {
            @include badge-variant($danger);
        }
        &.neutral {
            @include badge-variant($white);
            color: inherit;
        }
    }
}
