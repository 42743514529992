.dropdown-menu {
    border: 0;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border-radius: $border-radius-xs;
    @include transitions($fast-transition-time, $transition-linear);

    &.dropdown-menu-right {
        &:before,
        &:after {
            left: auto;
            right: 10px;
        }
    }

    &.dropdown-black {
        background: linear-gradient(to bottom, $black 0%, $black-states 100%);
        border: 1px solid $default;
        .dropdown-item {
            color: rgba(255, 255, 255, 0.7);
        }

        .dropdown-divider {
            border-color: $default;
        }

        &:before {
            color: $black;
            z-index: 2;
        }

        &:after {
            display: inline-block;
            position: absolute;
            width: 0;
            height: 0;
            z-index: 1;
            vertical-align: middle;
            content: "";
            top: -6px;
            left: 10px;
            right: auto;
            color: $default;
            border-bottom: 0.4em solid;
            border-right: 0.4em solid transparent;
            border-left: 0.4em solid transparent;
        }

        &.dropdown-menu-right {
            &:after {
                left: auto;
                right: 10px;
            }
        }

        .dropup & {
            &:after {
                color: $black-states;
                z-index: 2;
            }

            &:before {
                display: inline-block;
                position: absolute;
                width: 0;
                height: 0;
                vertical-align: middle;
                content: "";
                top: auto;
                bottom: -6px;
                right: auto;
                left: 10px;
                color: $dark-background;
                border-top: 0.4em solid;
                border-right: 0.4em solid transparent;
                border-left: 0.4em solid transparent;
                border-bottom: none;
                z-index: 1;
            }
        }
    }

    i {
        margin-right: 5px;
        position: relative;
        top: 1px;
    }

    .tim-icons {
        margin-right: 10px;
        position: relative;
        top: 4px;
        font-size: 18px;
        margin-top: -5px;
        opacity: 0.5;
    }

    .dropdown-item {
        &.active,
        &:active {
            color: inherit;
        }
    }

    .dropup & {
        &:before {
            display: none;
        }

        &:after {
            display: inline-block;
            position: absolute;
            width: 0;
            height: 0;
            vertical-align: middle;
            content: "";
            top: auto;
            bottom: -5px;
            right: auto;
            left: 10px;
            color: $white;
            border-top: 0.4em solid;
            border-right: 0.4em solid transparent;
            border-left: 0.4em solid transparent;
            border-bottom: none;
        }

        &.dropdown-menu-right {
            &:after,
            &:before {
                right: 10px;
                left: auto;
            }
        }
    }

    &:before {
        display: inline-block;
        position: absolute;
        width: 0;
        height: 0;
        vertical-align: middle;
        content: "";
        top: -5px;
        left: 10px;
        right: auto;
        color: $white;
        border-bottom: 0.4em solid;
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
    }

    &.dropdown-menu-right {
        right: 0 !important;
        left: auto !important;
    }

    .dropdown-item,
    .bootstrap-select &.inner li a {
        font-size: $font-size-sm;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        margin-top: 5px;
        @include transitions($fast-transition-time, $transition-linear);

        &:hover,
        &:focus {
            background-color: $opacity-gray-3;
        }

        &.disabled,
        &:disabled {
            color: $default-opacity;

            &:hover,
            &:focus {
                background-color: transparent;
                box-shadow: none;
            }
        }
    }

    .dropdown-divider {
        background-color: $opacity-gray-5;
    }

    .dropdown-header:not([href]):not([tabindex]) {
        color: $default-opacity;
        font-size: $font-size-xs;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }

    &.dropdown-primary {
        @include dropdown-colors(darken($primary, 3%), $opacity-8, $white, $opacity-2);
    }

    &.dropdown-info {
        @include dropdown-colors(darken($info, 3%), $opacity-8, $white, $opacity-2);
    }

    &.dropdown-danger {
        @include dropdown-colors(darken($danger, 3%), $opacity-8, $white, $opacity-2);
    }

    &.dropdown-success {
        @include dropdown-colors(darken($success, 3%), $opacity-8, $white, $opacity-2);
    }

    &.dropdown-warning {
        @include dropdown-colors(darken($warning, 3%), $opacity-8, $white, $opacity-2);
    }

    .dropdown &,
    .dropup:not(.bootstrap-select) &,
    .bootstrap-select &:not(.inner),
    &.bootstrap-datetimepicker-widget.bottom {
        @include transform-translate-y-dropdown(-20px);
        visibility: hidden;
        display: block;
        @include opacity(0);
        top: 100% !important;
    }

    &.bootstrap-datetimepicker-widget.top {
        @include transform-translate-y-dropdown(-20px);
        visibility: hidden;
        display: block;
        @include opacity(0);
    }

    &.bootstrap-datetimepicker-widget.top,
    &.bootstrap-datetimepicker-widget.bottom {
        @include transform-translate-y-dropdown(-20px);
    }

    .bootstrap-select.dropup &:not(.inner) {
        @include transform-translate-y-dropdown(25px);
    }

    .dropup:not(.bootstrap-select) & {
        @include transform-translate-y-dropdown(20px);
        top: auto !important;
        bottom: 100%;
    }

    .dropdown.show &,
    .bootstrap-select.show &:not(.inner),
    &.bootstrap-datetimepicker-widget.top.open,
    &.bootstrap-datetimepicker-widget.bottom.open,
    .dropup.show:not(.bootstrap-select) &,
    .navbar .dropdown.show & {
        @include opacity(1);
        visibility: visible;
        @include transform-translate-y-dropdown(1px);
    }

    &.bootstrap-datetimepicker-widget.top.open,
    &.bootstrap-datetimepicker-widget.bottom.open {
        @include transform-translate-y-dropdown(0px);
    }

    .dropup.show:not(.bootstrap-select) & {
        @include transform-translate-y-dropdown(-2px);
    }

    &.dropdown-navbar {
        left: -80px;
        &:before,
        &:after {
            left: auto;
            right: 17px;
        }
    }
}

.btn {
    cursor: pointer;

    &.dropdown-toggle[data-toggle="dropdown"] {
        padding: 10px;
        margin: 0;
        margin-bottom: 5px;

        &:after {
            content: "";
            margin-left: 5px;
        }
    }

    span.bs-caret {
        display: none;
    }

    &.btn-link {
        &.dropdown-toggle {
            height: 22px;
            padding: 0;
            margin-right: 5px;
        }
    }
}

.dropdown-toggle:after {
    content: unset;
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show > .btn.dropdown-toggle:focus {
    box-shadow: none;
}

// Dropown: Sizes

.dropdown-menu-sm {
    min-width: 100px;
    border: $border-radius-lg;
}

.dropdown-menu-lg {
    min-width: 260px;
    border-radius: $border-radius-lg;
}

.dropdown-menu-xl {
    min-width: 450px;
    border-radius: $border-radius-lg;
}

@include media-breakpoint-down(md) {
    .button-dropdown {
        display: none;
    }
    .dropdown-toggle:after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
}

@include media-breakpoint-up(lg) {
    .dropdown-menu .dropdown-item {
        color: $dark-gray;
    }
}
