.card-collapse {
    .card {
        margin-bottom: 0px;
        background: $transparent-bg;
        box-shadow: none;

        .card-header {
            position: relative;
            padding: 1rem 0.75rem;

            a[data-toggle="collapse"] {
                display: block;
                color: rgba($white, 0.6);

                i {
                    float: right;
                    position: relative;
                    color: $primary;
                    top: 1px;
                    @extend .animation-transition-general;
                }
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: rgba($white, 0.1);
            }
        }

        .card-body {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            color: rgba($white, 0.5);
        }

        &:last-child {
            .card-header:after {
                content: none;
            }
        }
    }
}
